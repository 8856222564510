/*@import "app.css";*/
/*@import "../font/HelveticaNeue/fonts.css";*/

body {
  font-family: "HelveticaNeueCyrR";
  font-weight: 500;
  font-style: normal;
  -webkit-font-smoothing: antialiased !important;
  font-size: 12px;
}

.h5,
h5 {
  font-size: 16px !important;
}

:root {
  --textBlue: rgb(50, 111, 202);

  --dgry: #333438;
  /*--mgry:#707073;*/
  --mgry: #3c3939;
  --lgry: #c1c2c3;

  --b: rgb(61, 120, 188);
  --lb: rgb(21, 141, 205);
  --newBlue: rgb(40, 57, 105);
  --hoverBlue: #0b6390;

  --lightBlue: rgb(16, 142, 206, 0.7);
  --divorserBlue: rgb(14, 125, 181);
  --lg: rgb(130, 169, 28);
  --dg: rgb(104, 136, 22);
  --o: rgb(243, 146, 0);
  --p: rgb(214, 11, 82);
  --darkGrey: rgba(120, 130, 140, 1);
  --lightGrey: rgb(218, 225, 229);
}

.primary {
  color: rgba(255, 255, 255, 0.87);
  background-color: var(--newBlue);
}

.btn-primary,
.btn-outline-primary:hover {
  color: #fff !important;
  background-color: var(--newBlue);
  border-color: var(--newBlue);
}

.btn-outline-primary,
.btn-primary:hover, .btn-primary:disabled {
  color: var(--newBlue) !important;
  background-color: #fff;
  border-color: var(--newBlue);
}

.btn_theme_primary:hover {
  background-color: var(--hoverBlue);
  cursor: pointer !important;
}

.b-primary {
  border-color: rgba(000, 160, 190, 1);
}

a.primary:hover {
  background-color: rgba(000, 160, 190, 1);
}

a.text-primary:hover {
  color: rgba(000, 160, 190, 1);
}

.text-primary,
.text-primary-hover a:hover {
  /*color: rgba(000, 160, 190, 1) !important;*/
  color: var(--newBlue) !important;
}

.pagination .page-item.active,
.pagination > .active > a,
.pagination > .active > span,
.pager .page-item.active,
.pager > .active > a,
.pager > .active > span {
  color: white;
  background-color: rgba(000, 160, 190, 1);
  border-color: rgba(000, 160, 190, 1);
}

.floatingLabel {
  color: var(--b) !important;
  opacity: 1;
  top: 0 !important;
  font-size: 0.85em !important;
}

.registrationBtn {
  color: rgba(255, 255, 255, 0.87);
  background: var(--newBlue);
}

.regBox {
  /*top:50%;*/
  /*left:50%;*/
  /*transform: translate(-50%, -50%);*/
  /*position:absolute;*/
  width: 410px;
  margin-bottom: 40px;
}

.regBoxText {
  font-size: 16px;
  font-family: "HelveticaNeueCyrM";
  color: var(--dgry);
}

.regBox label {
  letter-spacing: 1px;
  color: var(--mgry);
  opacity: 0.8;
}

.regField {
  margin-top: 10px;
}

.regField:focus {
  border-color: var(--b);
  border-bottom-width: 2px;
  padding-bottom: 1px;
}

.regField:focus ~ label {
  color: var(--b) !important;
  opacity: 1;
  top: 0 !important;
  font-size: 0.85em !important;
}

.additionalLink {
  color: var(--b) !important;
  font-family: "HelveticaNeueCyrM";
}

.secondText {
  color: var(--mgry);
  font-size: 14px;
}

.navbar-hd {
  background: var(--newBlue);
}

.navbar-md {
  min-height: 45px;
  padding-bottom: 8px;
  padding-top: 8px;
}

.leftPart {
  background: var(--newBlue);
  min-height: 100vh;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.logo_wrapper {
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
}

.logo_register {
  width: 200px;
  display: inline-block;
}

.imgSize {
  width: 20px;
}

@media (min-width: 992px) {
  .app-aside,
  .app-aside .scroll {
    width: 215px;
  }

  .app-aside:not(.hide) ~ .app-content {
    margin-left: 215px;
  }
}

.container-fluid {
  padding-left: 10px;
  padding-right: 10px;
}

#aside .nav {
  padding-top: 13px;
}

.navbar {
  max-height: 45px;
}

.navbar-brand {
  margin-top: -5px;
}

.navbar-brand img {
  height: 30px;
}

.additionalLink {
  padding-left: 15px;
}

.standard_table {
  /*max-width: 600px*/
  padding: 12px;
  width: 100%;
}

.standard_table table th {
  text-align: center;
  font-weight: bolder !important;
}

.standard_table table td {
  text-align: center;
}

.standard_table input {
  border: 1px solid var(--newBlue) !important;
  text-align: center;
}

.not_validated {
  color: red;
}

.validated {
  color: green;
}

input.not_validated {
  border: 1px solid red !important;
}

input.validated {
  border: 1px solid green !important;
}

.material_result_items_table input {
  text-align: center;
  width: 70px !important;
}

.material_result_items_table td {
  text-align: center;
}

.material_result_items_table .tc_1 {
  width: 80px;
  color: #fff;
  text-align: center;
  font-weight: bolder;
  background: var(--newBlue);
}

.material_result_items_table .tc {
  width: 80px;
  font-weight: bolder;
}

.material_result_items_table .tc_element {
  color: var(--newBlue);
  font-weight: bolder;
}

.material_result_items_table .tc_input {
  text-align: center;
}

.container-fluid.app {
  padding-left: 0px;
  padding-right: 0px;
}

.table tr td {
  vertical-align: middle;
}

.form_container {
  max-width: 940px;
}

h5 {
  font-weight: bold;
  padding-bottom: 8px;
  /*border-bottom: 1px solid var(--newBlue);*/
}

.form_wrapper {
  max-width: 900px;
}

.form_wrapper input {
  /*width: 400px;*/
  height: 30px;
}

.form_title {
  font-size: 16px;
  font-weight: bold;
  margin-left: 12px;
  margin-bottom: 10px;
}

.textarea {
  width: 100% !important;
}

.react-bs-table-tool-bar .col-xs-6:nth-child(1) {
  display: none;
}

.react-bs-table-search-form input {
  width: 300px;
  margin-bottom: 5px;
}

.dropdown-toggle::after {
  /*vertical-align: 2px;*/
  margin-top: 8px;
  float: right;
  margin-left: 5px;
}

.dropDownStyle {
  color: var(--mgry);
  opacity: 0.8;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;

  font-size: 18px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.dDMStyle {
  /*width: 330px;*/
  padding: 0px;
  max-height: 340px;
  overflow-y: scroll;
  overflow-x: hidden;
  /*margin-top: 47px;*/

  /*transform: translate3d(638px, 228px, 0px)!important;*/
}

.dropdown-item {
  white-space: pre-line;
  font-size: 16px;
}

.dDMStyle .dropdown-item {
  padding: 5px 20px;
  cursor: pointer;
  color: var(--mgry);
  opacity: 0.8;
}

.s-alert-wrapper .s-alert-top {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  min-width: auto;
  width: auto;
  left: 470px;
  right: 235px;
  bottom: auto;
}

.s-alert-box {
  margin-top: 30px;
  z-index: 100000000000;
}

.form_save_btn {
  margin-left: 15px;
}

.form_checkbox input {
  width: 20px !important;
}

.form_checkbox {
  vertical-align: middle;
}

.material_charge_items_table {
  font-weight: bolder;
}

.material_charge_items_table input {
  width: 50px;
}

.mc_head {
  color: #356fca;
}

.mc_min {
  color: red;
}

.mc_max {
  color: #356fca;
}

.cs_min {
  width: 50px;
}

.cs_unit {
  width: 50px;
}

.cs_max {
  width: 50px;
}

.cs_name {
  width: 300px;
}

.ms_max {
  width: 50px;
}

.ms_min {
  width: 50px;
}

.ms_periodic {
  width: 300px;
}

.form_select_box {
  height: 25px;
}

.title_icon {
  filter: brightness(0%);
  height: 30px;
  padding: 5px;
  margin-top: -5px;
  /*border: 1px solid red;*/
}



.result_del {
  cursor: pointer;
  text-align: center;
  display: block;
  font-size: 15px;
  display: none;
  position: relative;
  z-index: 100000000;
}

.result_block:hover .result_del {
  display: block;
}

.input_result {
  width: 40px;
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;
}

.input_result_conform {
  width: 91px;
  text-align: center;
  text-align-last: center;
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;
}

.result_block {
  vertical-align: top;
  display: inline-block;
  text-align: center;
}

.result_idx {
  text-align: center;
  display: block;
  font-size: 10px;
}

.add_result_btn {
  margin-top: 10px;
}

.minmax_block {
  text-align: center;
  display: inline-block;
  margin-right: 20px;
}

.modal .modal-content {
  overflow: hidden;
}

.modal-body {
  max-height: 85vh;
  overflow: auto;
}

.modal-title {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.modal-header {
  padding-bottom: 5px;
}

.modal.fade {
  background: rgba(0, 0, 0, 0.51);
}

.stateLabel {
  line-height: 20px;
  font-size: 11px;
  font-weight: bolder;
  margin-left: 5px;
  margin-right: 5px;
}

.table th {
  font-weight: bolder;
  font-size: 13px;
}

.react-bs-table table td,
.react-bs-table table th {
  overflow: scroll;
  white-space: nowrap;
  text-overflow: clip !important;
}

.rowNoP [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.rowProduct {
  margin-left: 0px;
}

.rowNoP .form_title {
  font-size: 16px;
  font-weight: bold;
  margin-left: 0px;
  margin-bottom: 10px;
}

label {
  /*color: var(--textBlue);*/
  font-weight: bolder;
  font-size: 14px;
}

.infoBlock .value {
  font-weight: bolder;
}

.infoBlock i {
  font-size: 20px;
  color: var(--textBlue);
  cursor: pointer;
}

.infoBlock i:hover {
  /*border: 1px solid var(--textBlue);*/
  /*padding: 7px;*/
}

.nav-group {
  display: block;
  padding: 0 20px;
  line-height: 2rem;
  position: relative;
  font-weight: bolder;
  font-size: 10px;
}

.rowType {
  min-width: 170px;
}

.resultValue {
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;

  border: 1px #000 solid;
  margin-right: 7px;
}

.resultValue_valid {
  border: 1px green solid;
  color: green;
}

.resultValue_invalid {
  border: 1px red solid;
  color: red;
}

.label-warning {
  color: red;
}

.commentText {
  width: 100%;
  height: 70px;
}

.printBtn,
.printBtn i {
  color: #fff;
}

.dropzone {
  width: 400px;
  padding: 20px;
  padding-top: 40px;
  border: 1px solid #cccccc;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}

.or {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 10px;
}

.uploadPdf {
  margin-bottom: 10px;
  border: 1px solid #ccc;
  padding: 5px;
}

.labelCertificate {
  font-size: 14px;
  font-style: italic;
}

.iconDelete {
  float: right;
  color: red;
}

.deleteImage {
  color: red;
  cursor: pointer;
  font-size: 20px;
}

.dropzone {
  width: 400px;
  padding: 20px;
  padding-top: 40px;
  border: 1px solid #cccccc;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}

.inputDisabled {
  background: #cccccc;
}

.facilitySelect {
  height: 28px;
}

.csv-label {
  display: block;
}

.csv-reader-input span {
  display: block;
}

.csv-reader-input .csv-input {
  width: 100px;
}

.navside .nav li {
  border: inherit;
  position: relative;
  min-height: 50px;
}

.selectBox {
  height: 30px;
}

.avatarProfile {
  text-align: center;
  width: 100% !important;
}

.avatarProfile img {
  margin-bottom: 20px;
  height: 150px;
  width: 150px;
  display: inline-block;
}

.taskBlock .text-muted,
.merchandiserBlock .text-muted {
  font-size: 17px;
}

.merchandiserBlock,
.taskBlock {
  text-align: center;
}

.taskTags .label-lg {
  margin: 10px;
}

.taskImages img {
  height: 200px;
  padding: 10px;
  cursor: pointer;
}

.taskImagesEdit img {
  height: 200px;
  padding: 10px;
  cursor: pointer;
}

.trHead {
  font-weight: bold;
  font-size: 17px;
}

.label {
  margin: 3px;
}

.pin img {
  width: 20px;
  padding-top: 7px;
}

.nav-active-primary .nav-link.active,
.nav-active-primary .nav > li.active > a {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: var(--newBlue) !important;
}
.b-primary {
  border-color: var(--newBlue) !important;
}

.viewItem h3 {
  font-size: 20px;
}

.dayLabel {
  text-transform: uppercase;
}

.app-body h5 {
  text-transform: uppercase;
}

.tableRoute .css-2b097c-container {
  width: 350px;
  display: inline-block;
}

.boxForm .css-g1d714-ValueContainer {
  /* padding-top: 3px; */
}

.boxEdit {
  width: 50%;
  display: inline-block;
}

.boxForm .datePicker {
  text-align: center;
}

.boxForm input,
.boxForm .dropDownStyle,
.tableRoute input {
  /* height: 40px; */
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 5px;
}

.react-datepicker-wrapper {
  display: block !important;
}

.datePicker {
  border-color: #ccc;
}

.dropDownStyle {
  color: var(--newBlue);
  border-color: var(--newBlue);
  background: none;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

.navAside {
  text-transform: uppercase;
}

.faIcon {
  font-size: 16px;
  margin-right: 3px;
}

.labelPoint {
  font-size: 15px;
}

.routeDelete {
  display: none;
  margin-left: 10px;
}

.routePointRow {
  margin-top: 10px;
}

.routePointRow:hover .routeDelete {
  display: inline-block;
  color: red;
  width: 30px;
  cursor: pointer;
  font-size: 18px;
}

.routeAdd {
  cursor: pointer;
}

.entityDropdown {
  display: inline-block;
}

.routePoints {
  margin: auto;
  /*width: 80%;*/
  /*border: 3px solid green;*/
  padding: 10px;
  text-align: center;
}

.routePointsAdd {
  margin-top: 10px;
}

table thead {
  text-transform: uppercase;
}

/*TABLE ROUTE*/
.tableRouteTitle input {
  width: 100%;
}

.tableRouteHead {
  text-transform: uppercase;
  font-weight: bold;
}

.tableRouteDay {
  width: 150px;
  text-transform: uppercase;
  font-weight: bold;
}

.tableRouteTitle {
  width: 200px;
}

.KFormDelete {
  color: red;
  display: none;
  margin-left: -25px;
  margin-right: 5px;
  cursor: pointer;
  font-size: 15px;
}

.KForm-firstRow:hover .KFormDelete {
  display: inline-block;
}

.tableKForm input,
.tableKForm textarea {
  /*width: 100%;*/
  height: 25px;
}

.tableKForm .css-2b097c-container {
  width: 100%;
  height: 25px;
}

.tableKForm .css-2b097c-container {
  font-size: 12px;
  /*min-width:70px;*/
}

.tableKForm .css-tlfecz-indicatorContainer {
  padding: 0px;
}

.tableKForm tr {
  padding: 0;
  margin: 0;
  width: 100%;
}

.tableKForm td {
  padding: 0;
  margin: 0;
}

.tableKForm .css-yk16xz-control,
.tableKForm .css-1hb7zxy-IndicatorsContainer,
.tableKForm .css-2b097c-container {
  height: 20px !important;
}

.tableKForm input {
  width: 100%;
}

.tableKForm td {
  /*width: 80px;*/
}

.tableKForm .td1 {
  width: 200px;
}

.tableKForm .td2 {
  width: 200px;
}

.tableKForm .td4 {
  width: 90px;
}

.tableKForm .td5 {
  width: 90px;
}

.tableKForm .td9 {
  width: 60px;
}

.tableKForm .td10 {
  width: 60px;
}

.tableKForm td {
  padding: 3px;
  text-align: center;
}

.tableKForm thead {
  font-weight: 900;
}

.kform-date {
  width: 70px;
}

.kform-price {
  width: 50px;
}

.kform-discount {
  width: 50px;
}

.react-bs-table .table-bordered {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.box,
.tableRoute {
  border-radius: 5px;
}

.searchInput {
  width: 220px;
}

.clearFilter {
  border-radius: 50%;
  color: #0a6ea0;
  padding: 5px;
  border: 1px solid #0a6ea0;
  cursor: pointer;
}

.fileTable {
  width: 100%;
  font-size: 16px;
}

.fileTable .fileName {
  width: 50%;
}

.fileTable .header {
  font-size: 18px;
  font-weight: bolder;
}

.fileTable td:nth-child(1) {
  text-align: left;
}

.deleteFile {
  cursor: pointer;
  border: 1px solid var(--newBlue);
  width: 50px;
  display: inline-block;
  margin: 5px;
}

.deleteFile:hover {
  background: var(--newBlue);
  color: #fff;
}

.previewPhotoNav i {
  font-size: 21px;
  cursor: pointer;
  color: #fff;
}

.previewPhotoNav i:hover {
  color: #0a6ea0;
}

.acceptBtn {
  border: 0;
  background-color: #009688;
}

.rejectBtn {
  border: 0;
  background-color: #e30713;
}

.fileTable {
  color: #212529;
  font-size: 12px;
}

.photoCanvas {
  padding: 8px;
}
.photoCanvas img {
  max-width: 400px;
}

.smallImage {
  height: 200px !important;
}

.w-100 {
  width: 100%;
}

.photoCanvas #myCanvas {
  width: 100%;
  height: auto;
}

.bi-report iframe {
  min-height: 800px;
}

.error {
  color: red;
}

.pointFilter {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 5px;
}

.answer {
  border: 1px solid var(--newBlue);
  padding: 10px;
  margin: 10px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 -1px 0px rgb(0 0 0 / 2%);
}

.searchInputPhoto {
  display: inline-block;
  height: 44px;
}

.openPhoto {
  display: inline-flex;
  gap: 10px;
}
